import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect() {
    this.element.addEventListener("submit", () => {
      if (this.hasSubmitTarget) {
        this.submitTarget.disabled = true;
        this.submitTarget.innerText = "Submitting...";
      }
    });
  }
}
