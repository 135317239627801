import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit", "input"];

  connect() {
    this.element.addEventListener("submit", () => {
      // Disable button and show spinner
      if (this.hasSubmitTarget) {
        this.submitTarget.disabled = true;
        this.submitTarget.innerHTML = `<i class="fas fa-spinner fa-spin"></i>`;
      }
    });
  }
}
